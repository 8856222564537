import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

let initialState = {
    domain:"",
    
}

 const domainSlice = createSlice({
    name:"domain",
    initialState,
    reducers:{
        getDomain:(state,action)=>{
            state.domain = action.payload
           
        }
    }
})

export const {getDomain} = domainSlice.actions;
export default domainSlice.reducer;